<template>
  <div>
    <div class="banner">
      <div class="banner-content">
        <h2>成功案例</h2>
        <p> 超过10000家企业通过客宝宝平台服务客户</p>
      </div>
    </div>
    <div class="succnav">
      <a href="#">全部行业</a>
      <a href="#">机电设备</a>
      <a href="#">医疗器械</a>
      <a href="#">家用电器</a>
      <a href="#">仪器仪表</a>
      <a href="#">智能制造</a>
      <a href="#">汽车车辆</a>
      <a href="#">IT运维</a>
      <a href="#">安防监控</a>
      <a href="#">净水设备</a>
      <a href="#">其它行业</a>
    </div>
    <div class="lists">
      <div class="list">
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/bacl1.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                美国倍科电子
              </div>
              <div class="card-content">
                倍科(简称BACL)，成立于1996年，总部位于美国硅谷，是全球化、综合性的国际第三方检测认证机构。BACL一直致力于为全球进出口企业提供最优质、最全面的检测认证服务。在过去二十几年中，BACL以专业、严谨、周到的服务理念，为国内外众多企业提供了帮助，已获得多家认可机构认可。
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/cysy1.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                创裕实业
              </div>
              <div class="card-content">
                东莞市创裕实业发展有限公司是一家集房地产开发、物业管理、市场经营、商贸、物流运输等领域的综合性企业。公司成立于1995年。目前旗下子公司有东莞市裕隆物业管理有限公司、东莞市中大海洋生物技术工程有限公司、东莞市德基房地产开发有限公司以及其它合作经营机构。
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/gwfc1.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                光威复材
              </div>
              <div class="card-content">
                威海光威复合材料股份有限公司是国内碳纤维行业第一家A股上市公司（证券代码：300699），成立于1992年，隶属于威海光威集团，是致力于高性能碳纤维及复合材料研发和生产的高新技术企业。 公司是目前国内碳纤维行业生产品种最全、生产技术最先进、产业链最完整的龙头企业之一。
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/lxkx1.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                立心科学
              </div>
              <div class="card-content">
                立心科学致力于生物医用新材料和骨科植入医疗器械的开发。目前已在深圳建立研发中心，公司目前已与上海六院，上海九院，深圳南山医院等多家医院，与新加坡国立大学，上海交通大学，浙江大学等多家国内外高校建立了合作关系。公司致力于生物材料和骨科医疗器械的开发。
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/mzlj1.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                面子逻辑
              </div>
              <div class="card-content">
                面子逻辑FACELOGIC作为科学护肤领域的先驱者，同时也是新时代皮肤健康管理模式的开创者，更将是一个颠覆美丽事业的引领者。面子逻辑，用科学逻辑诠释肌肤美学。
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/qbzn.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                圻博智能
              </div>
              <div class="card-content">
                圻博智能设备有限公司是一家专业的贴标、焊接、搬运、喷涂等非标设备设计、生产及销售于一体的公司，公司的产品主要服务电子通讯、汽车、塑胶、家电、五金、灯饰、等行业。充分发挥产品的优势、提高劳动效率、减少产业的人工开支。
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/wazdh1.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                韦安自动化
              </div>
              <div class="card-content">
                韦安自动化成立于2004年，是一家集科研、生产、销售和服务为一体的自动化公司。公司秉承以质量求生存，以创新求发展的理念，把质量和创新放在首位，帮助客户实现半自动、全自动化生产作业，从而降低用户生产损耗，减少用户用工数量，提高用户生产效益，最终实现双赢。
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-item">
            <div class="card-img">
              <img src="../../../public/clients/bay.jpg" alt="">
            </div>
            <div class="content">
              <div class="card-title">
                白阿姨
              </div>
              <div class="card-content">
                白阿姨是国内领先的洗护物联网服务商，白阿姨打通干洗生态（洗衣工厂、收发柜、用户）搭建线上互联网营销服务及线下物联网管理平台，从而提升洗衣企业的盈利能力、提高洗衣企业的生产效率与管理水平，推动中国洗衣行业的改造升级。
              </div>
            </div>
          </div>
        </div>
      </div>
	<router-link to="/register">
      <div class="succMore_btn">
        了解更多
      </div>
	</router-link>
    </div>
    <div class="new">
      <div class="title">客宝宝，开启售后服务新时代</div>
      <div class="title-content">无需购买服务器、无需运维人员，免费注册即可为您的客户服务开启新的篇章</div>
<!--      <div class="title-button" to="/register">
        立即免费试用
      </div> -->
		<router-link to="/register">
			<div class="title-button">立即免费试用</div>
		</router-link>
    </div>
  </div>

</template>

<script>

export default {
  name: "SuccessfulCases",
  mounted() {
    console.log("SuccessfulCases", Boolean(this._isMobile()));
    if (this._isMobile()) {
      // 为true是移动端,跳至移动端页面
      console.log("移动端");
      this.$router.replace('/mobileSuccessfulCases')
    } else {
      // 为false是pc端，跳至pc端页面
      console.log("PC端");
      this.$router.replace('/SuccessfulCases')
    }
  },
  methods: {
    // 判断是什么设备
    _isMobile() {
      let plat = navigator.userAgent.match( // 判断不同端
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return plat;
    }
  },
}
</script>

<style lang="scss" scoped>

.banner {
  height: 500px;
  background-image: url("../../../public/sucessbanner.png");
  color: #fff;
  text-align: left;

  .banner-content {
    margin-left: 500px;

    h2 {
      font-size: 54px;
      color: #fff;
      line-height: 56px;
      padding-top: 180px;
    }

    p {
      font-size: 26px;
      color: #fff;
      line-height: 30px;
      margin-top: 21px;
      font-family: 'pingfang';
    }
  }
}

.succnav {
  border-top: 1px solid #E7E7E7;
  margin-top: 28px;
  line-height: 79px;
  border-bottom: 1px solid #E7E7E7;

  a {
    display: inline-block;
    margin-right: 42px;
    font-size: 17px;
    color: #000000;
    text-decoration: none
  }

  a:last-child {
    margin-right: 0;
  }

  a.checked {
    color: #E62129;
  }

  a:hover {
    color: #E62129;
  }
}
a{ text-decoration:none} 
.lists{
	text-decoration:none;
    margin-bottom: 50px;
  .succMore_btn {
    display: block;
    width: 185px;
    height: 50px;
    border: 1px solid #2BCDA8;
    text-align: center;
    line-height: 50px;
    margin: 0 auto;
    font-size: 17px;
    color: #2BCDA8;
    font-family: 'pingfang';
    border-radius: 5px;
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
    .card {
      width: 363px;
      height: 409px;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.15);
      border-radius: 6px;
      overflow: hidden;
      margin-top: 60px;
      margin-right: 55px;

      .content {
        padding: 0 40px;
        text-align: left;

        .card-title {
          font-size: 18px;
          color: #000000;
          font-weight: bold;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 20px;
          margin-top: 35px;
        }

        .card-content {
          font-size: 16px;
          color: #666666;
          font-weight: 500;
          line-height: 27px;
          margin-top: 18px;
          width: 100%;
        }
      }

    }
  }
}
.new {
  background: url("../../../public/succ_register_bg.png");
  height: 300px;
  .title {
    font-size: 40px;
    color: #ffffff;
    line-height: 40px;
    padding-top: 60px;
  }

  .title-content {
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    line-height: 30px;
    padding-top: 20px;
  }

  .title-button {
    width: 186px;
    line-height: 53px;
    background: #E62129;
    display: inline-block;
    font-size: 19px;
    color: #fff;
    border-radius: 5px;
    margin-top: 50px;
  }
}

</style>
